import {
  Experimental_CssVarsProvider as CssVarsProvider,
  experimental_extendTheme as extendTheme,
} from '@mui/material/styles';
import * as React from 'react';
import { type ReactNode } from 'react';

const theme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {
          main: '#2a9461',
        },
        secondary: {
          main: '#494c7d',
        },
      },
    },
  },
  components: {
    // Name of the component
    MuiDialogContent: {
      defaultProps: {
        sx: {
          backgroundColor: '#fafafa',
        },
      },
    },
    MuiDialogTitle: {
      defaultProps: {
        sx: {
          backgroundColor: '#fafafa',
        },
      },
    },
    MuiDialogActions: {
      defaultProps: {
        sx: {
          backgroundColor: '#fafafa',
          borderTop: '1px solid #eeeeee',
        },
      },
    },
  },
});

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  return <CssVarsProvider theme={theme}>{children}</CssVarsProvider>;
};
