import { type LoaderFunctionArgs, redirect } from 'react-router-dom';

import { authStore } from '@/features/auth/authStore';


/**
 * Prevent a nav to the login page if the user is already authenticated
 */
export const loginLoader = ({ request }: LoaderFunctionArgs) => {
  if (authStore.isAuthenticated) {
    const url = new URL(request.url);
    const from = url.searchParams.get('from');
    return redirect(from ?? '/');
  }
  return null;
};
