import { CssBaseline, Typography } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import * as React from 'react';
import { type ReactNode, useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

import { ThemeProvider } from '@/components/Theme';
import { effect } from '@/lib/utility';

import { createRouter } from './routes';

import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import './index.css';

import {
  appMessage,
  AppMessageLevel,
  type AppMessagePayload,
  appStore,
} from '@/features/app/appStore';

const queryClient = new QueryClient();
const router = createRouter();

const isError = (err: unknown): err is Error => err instanceof Error;

const toastMessage = (payload: AppMessagePayload, level?: AppMessageLevel) => {
  if (level === undefined) {
    level = isError(payload) ? AppMessageLevel.ERROR : AppMessageLevel.INFO;
  }

  const message = () => {
    const text = isError(payload)
      ? payload.message
      : typeof payload === 'string'
        ? payload
        : undefined;

    if (text)
      return (
        <Typography variant="body1" color="color.primary">
          {text}
        </Typography>
      );

    return payload as ReactNode;
  };

  switch (level) {
    case AppMessageLevel.ERROR:
      toast.error(message());
      break;
    case AppMessageLevel.INFO:
      toast.info(message());
      break;
    case AppMessageLevel.SUCCESS:
      toast.success(message());
      break;
    case AppMessageLevel.WARNING:
      toast.warning(message());
      break;
  }
};

const App = () => {
  useEffect(() => {
    const isUsingWindows = /windows/gi.test(navigator.userAgent);
    if (isUsingWindows) {
      document.querySelector('html')?.setAttribute('os', 'windows');
    }
  }, []);

  // Handle the Toast
  effect(() => {
    const message = appMessage.value?.payload ?? null;
    if (message != null) {
      console.log('Another message', message);
      toastMessage(message, appMessage.value?.level);
      appStore.clearMessage();
    }
  }, 'Displaying toast message');

  return (
    <ThemeProvider>
      <ToastContainer limit={1} theme="colored" />
      <QueryClientProvider client={queryClient}>
        <CssBaseline />
        <RouterProvider
          router={router}
          fallbackElement={<p>Initial load...</p>}
        />
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default App;
