import { type ReadonlySignal } from '@preact/signals-core';
import {
  batch as preactBatch,
  computed as preactComputed,
  effect as preactEffect,
  type Signal,
  signal as preactSignal,
} from '@preact/signals-react';
import { parse, v4 } from 'uuid';

export const toRaw = (val: any): any => {
  if (val instanceof Array) return val.map((val) => toRaw(val));
  if (val instanceof Object)
    return Object.fromEntries(
      Object.entries(Object.assign({}, val)).map(([k, v]) => [k, toRaw(v)]),
    );
  return val;
};

export const toRaw2 = (val: any): any => {
  if (val instanceof Array) return val.map((val) => toRaw2(val));
  if (val instanceof Object)
    return Object.fromEntries(
      Object.entries(Object.assign({}, val))
        .filter(([k, _]) => k !== 'parent')
        .map(([k, v]) => [k, toRaw2(v)]),
    );
  return val;
};

export const debounce = (func: any, delay: number) => {
  let timeoutId: number;

  // noinspection UnnecessaryLocalVariableJS
  const debounceHandler = (...args: any[]) => {
    timeoutId !== undefined && clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      func(...args);
    }, delay) as unknown as number;
  };

  return debounceHandler;
};

export const debounceSignal = <T>(
  targetSignal: Signal<T>,
  timeoutMs = 0,
): Signal<T> => {
  const debounceSignal = preactSignal<T>(targetSignal.value);

  preactEffect(() => {
    const value = targetSignal.value;
    const timeout = setTimeout(() => {
      debounceSignal.value = value;
    }, timeoutMs);
    return () => {
      clearTimeout(timeout);
    };
  });

  return debounceSignal;
};

const v4Base64UUID = () => {
  const bytes = parse(v4());
  const base64 = btoa(String.fromCharCode(...bytes));
  return base64.substring(0, 22);
};

const invalidChars = /[.,/~+*?%]/gi;

export const base64UUID = () => {
  let uuid = '';
  while (!uuid || uuid.match(invalidChars)) {
    uuid = v4Base64UUID();
  }
  return uuid;
};

export const cx = (...args: any[]) => {
  return args
    .filter((arg) => {
      if (typeof arg === 'string') {
        return !!arg.trim();
      }
      if (typeof arg === 'object') {
        return Object.keys(arg).some((key) => arg[key]);
      }
      return false;
    })
    .join(' ');
};

export const clone = <T>(obj: T): T => {
  const s = `Cloning`;
  console.time(s);
  try {
    return structuredClone(obj);
  } finally {
    console.timeEnd(s);
  }
};

export const cleanAndClone = <T>(obj: T): T => {
  const s = `Cleaning (removing undefined) and cloning`;
  console.time(s);
  try {
    return JSON.parse(JSON.stringify(obj));
  } finally {
    console.timeEnd(s);
  }
};

export function isArray(x: any) {
  return Object.prototype.toString.call(x) === '[object Array]';
}

export class AssertionError extends Error {
  constructor(message: string, options?: ErrorOptions) {
    super(message ?? 'Unspecified error', options);
  }
}

export const isPlainObject = (prop: any) =>
  Object.prototype.toString.call(prop) === '[object Object]';

export const assertExists = (o: any, message: string) => {
  return assert(o !== undefined && o !== null, message);
};

export const assertEqual = (actual: any, expected: any, message: string) => {
  return assert(actual === expected, message);
};

export const assert = (cond: boolean, message: string) => {
  if (!cond) throw new AssertionError(message);
};

const isDevMode = () => {
  // return import.meta.env.MODE !== 'production';
  return true;
};

export const timeLog = <T>(func: () => T, message?: string): T => {
  if (!isDevMode()) {
    return func();
  }

  const s = message ?? `Timing ${func.toString()}`;

  console.time(s);
  try {
    return func();
  } finally {
    console.timeEnd(s);
  }
};

export const debugLog = (...args: any[]) => {
  if (isDevMode()) {
    try {
      console.log(args);
    } catch (e) {
      console.warn('Failed to log');
    }
  }
};

export const signal = <T>(initial: T, label?: string): Signal<T> => {
  const result = preactSignal(initial);
  if (isDevMode()) {
    preactEffect(() => {
      debugLog(
        `${label ?? 'Unlabeled Signal'} has been modified`,
        result.value,
      );
    });
  }
  return result;
};
export const computed = <T>(
  compute: () => T,
  label?: string,
): ReadonlySignal<T> => {
  const result = preactComputed(compute);
  if (isDevMode()) {
    preactEffect(() => {
      debugLog(
        `${label ?? 'Unlabeled Computed'} has been modified`,
        result.value,
      );
    });
  }
  return result;
};

export const batch = <T>(callback: () => T, label = v4Base64UUID()): T => {
  return preactBatch(() => {
    const start = performance.now();
    try {
      debugLog(`Begin Batch - ${label}`);
      return callback();
    } finally {
      debugLog(
        `End Batch - ${label} (${(performance.now() - start).toFixed(1)} ms)`,
      );
    }
  });
};

export const effect = (
  compute: () => unknown,
  label = v4Base64UUID(),
): VoidFunction => {
  return preactEffect(() => {
    const start = performance.now();
    try {
      debugLog(`Begin Effect - ${label}`);
      compute();
    } finally {
      debugLog(
        `End Effect - ${label} (${(performance.now() - start).toFixed(1)} ms)`,
      );
    }
  });
};

export const subsetArray = <T>(arr: T[], partitions: number): T[][] => {
  // shallow copy of original array
  const a = [...arr];

  const partitionSize = Math.ceil(a.length / partitions);
  const result: T[][] = [];

  while (a.length > 0) {
    result.push(a.splice(0, partitionSize));
  }

  return result;
};

export const convertWildcard = (pattern: string): RegExp | null => {
  pattern = pattern ?? '*';

  const regex = pattern
    .replaceAll('(', '\\(')
    .replaceAll(')', '\\)')
    .replaceAll('{', '\\{')
    .replaceAll('}', '\\}')
    .replaceAll('/', '\\/}')
    .replaceAll('+', '\\+')
    .replaceAll('.', '\\.')
    .replaceAll('*', '.*')
    .replaceAll('?', '.');

  try {
    return new RegExp(regex, 'ig');
  } catch (e) {
    return null;
  }
};
