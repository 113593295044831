import { type ReactNode } from 'react';

import { signal } from '@/lib/utility';

enum MenuState {
  open,
  close,
}

export type AppMessagePayload = Error | ReactNode | string | null;

export enum AppMessageLevel {
  ERROR,
  INFO,
  SUCCESS,
  WARNING,
}

export interface AppMessage {
  payload: AppMessagePayload;
  level?: AppMessageLevel;
}

/// SIGNALS

// This signal will activate the Toast when there is a notification for the user
export const appMessage = signal<AppMessage | null>(null, 'AppMessage');
const menuState = signal<MenuState>(MenuState.close, 'MenuState');
export const spinnerState = signal<boolean>(false, 'SpinnerState');

export interface AppStore {
  toggleMenu: () => void;
  openMenu: () => void;
  closeMenu: () => void;
  spinnerOn: () => void;
  spinnerOff: () => void;
  showMessage: (message: AppMessagePayload, level?: AppMessageLevel) => void;
  clearMessage: () => void;
}

export const appStore: AppStore = {
  spinnerOn(): void {
    spinnerState.value = true;
  },

  spinnerOff(): void {
    spinnerState.value = false;
  },

  closeMenu(): void {
    menuState.value = MenuState.close;
  },

  openMenu(): void {
    menuState.value = MenuState.close;
  },

  toggleMenu(): void {
    if (menuState.peek() === MenuState.open) {
      this.closeMenu();
    } else {
      this.openMenu();
    }
  },

  showMessage(message: AppMessagePayload, level?: AppMessageLevel): void {
    appMessage.value = {
      payload: message,
      level,
    };
  },

  clearMessage(): void {
    appMessage.value = null;
  },
};
