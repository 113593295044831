import { batch, signal } from '@/lib/utility';

interface AuthState {
  isAuthenticated: boolean;
  username: string | null;
}

interface AuthStore {
  readonly isAuthenticated: boolean;
  readonly username: string | null;
  setIsAuthenticated: (
    isAuthenticated: boolean,
    username: string | null,
  ) => void;
  signOut: () => void;
}

/// SIGNALS

export const authState$ = signal<AuthState>(
  { isAuthenticated: false, username: null },
  'AuthState',
);

export const authStore: AuthStore = {
  get username() {
    return authState$.peek().username;
  },

  get isAuthenticated() {
    return authState$.peek().isAuthenticated;
  },

  setIsAuthenticated(
    isAuthenticated: boolean,
    username: string | null = null,
  ): void {
    batch(() => {
      authState$.value.isAuthenticated = isAuthenticated;
      authState$.value.username = username;
    }, 'setIsAuthenticated');
  },

  signOut(): void {
    this.setIsAuthenticated(false);
  },
};
