import { Backdrop, CircularProgress } from '@mui/material';
import * as React from "react";

import { spinnerState } from '@/features/app/appStore';

export default function FullPageSpinner() {
  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={spinnerState.value}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
